<template>
<v-container fluid>
  <h2 class="d-flex justify-space-between">Artwork sales performance (since 2022)</h2>
  <select-artist></select-artist>
  <v-data-table v-if="filteredArtworks.length && activeArtist"
    :headers="headers"
    :items="filteredArtworks"
    :disable-pagination="true"
    :hide-default-footer=true
    class="elevation-1"
  >
    <template v-slot:[`item.image`]="{ item }">
      <v-img class="my-2" max-height="150px" max-width="150px"
          :src="`${imageUrl}/300px/${item.image}`"
        ></v-img>
    </template>
    <template v-slot:[`item.value`]="{ item }">
      <span>£{{item.value.toFixed(2)}}</span>
    </template>
  </v-data-table>
</v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportApi from '@/api/admin/report'
import xlsx from 'xlsx'
import SelectArtist from '@/components/SelectArtist'

export default {
  components: {
    SelectArtist
  },
  data () {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      artworks: [],
      artistCode: null,
      // filteredArtworks: [],
      headers: [
        {
          text: 'Image',
          sortable: false,
          value: 'image'
        },
        {
          text: 'Artwork Code',
          sortable: true,
          value: 'artworkCode'
        },
        {
          text: 'Artist',
          sortable: true,
          value: 'artist'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Total Revenue',
          align: 'end',
          value: 'value'
        },
        {
          text: 'Total',
          width: '8%',
          align: 'end',
          value: 'count'
        },
      ]
    }
  },
  methods: {
    async getSales (dates) {
      ReportApi.loadArtistUnsoldProducts().then((results) => {
        this.artworks = results.data
      })
      ReportApi.loadProductSales({ dateRange: dates }).then((results) => {
        this.sales = results.data
        this.products = _.groupBy(this.sales, 'category')
        for (const product in this.products) {
          let totalSalesItems = 0
          const ranked = this.products[product].map((item, index) => {
            totalSalesItems += item.numItems
            return { ...item, index: index + 1 }
          })
          this.products[product] = { total: totalSalesItems, products: ranked }
        }
        this.loaded = true
      })
    },
    downloadSalesPerformance () {
      const headers = [
          'sku',
          'category',
          'artistName',
          'title',
          'skuSequence'
      ]
      const workbook = xlsx.utils.book_new()
      const worksheet = xlsx.utils.json_to_sheet(this.sales, { header: headers })
      xlsx.utils.book_append_sheet(workbook, worksheet, 'Sales')
      xlsx.writeFile(workbook, 'PRODUCT-SALES.xlsx')
    },
    async setArtist (artistCode) {
      this.artistCode = artistCode
    },
  },
  computed: {
    ...mapGetters('reports', ['activeArtist']),
    filteredArtworks () {
      return this.artworks.filter( item => {
        return this.activeArtist ? item.artistCode === this.activeArtist : true
      })
    }
  },
  async mounted () {
    ReportApi.loadArtistUnsoldProducts().then((results) => {
      this.artworks = results.data
    })
  }
}
</script>
